@media only screen and (max-width: 768px) {
    .container-admin-content{
        flex-direction: column !important;
    }
    .div-admin-sidebar{
        max-width: 100% !important;
    }
}
.underline-admin{
    width: 100%;
    height: 3px;
    background-color: #DCDCDC;
    margin-bottom: 1em;
}
.container-admin-content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100% !important;
    height: 100%;
}
.div-admin-sidebar{
    height: auto;
    width: auto;
    max-width: 141px;
}
.div-admin-route{
    width: 100%;
}

.adminTitle{
    padding: 0;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    font-size: large;
    color: RGB(50, 50, 50);
}
.sectionTitle{
    font-size: large;
    color: RGB(50, 50, 50)
}
