.c-orange {
  color: white;
  background-color: rgb(248, 133, 1);
}


.c-orange:hover {
  background-color: rgb(211, 120, 0);
}

.c-green {
  color: white;
  background-color: rgb(12, 168, 25);
}

.c-green:hover {
  background-color: rgb(6, 133, 17);
}

*:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.13);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.336);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0000008f;
}


/* Create four equal columns that floats next to each other */
.column-annonce {
  float: left;
  width: 20%;
  padding: 10px;
  min-width: 200px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row-annonce:after {
  content: "";
  display: table;
  clear: both;
}

.row-annonce {
  margin-left: -1%;
}

.my_title_1 {
  font-size: x-large;
  font-weight: bold;
  color: rgb(248, 133, 1);
}

.myLabel {
  font-size: small;
  margin-right: 5px;
}

.my-form-control {
    font-size: small;
  height: 35px;
  width: 100%;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.267);
  margin-bottom: 20px;
}
.my-form-control:hover,
.my-form-control:focus {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.198);
}

.my-btn-lg {
  height: 45px;
  border: none;
  border-radius: 100px;
  font-size: small;
  padding-left: 3%;
  padding-right: 3%;
  color: white;
  min-width: 150px;
}

.my-btn {
  height: 35px;
  border: none;
  border-radius: 100px;
  font-size: small;
  padding-left: 3%;
  padding-right: 3%;
  color: white;
}

.my-btn-form {
  height: 35px;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: small;
}

.my-btn-border {
  background-color: transparent;
  height: 35px;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-size: small;
}

.c-orange-border {
  border: 1px solid rgb(248, 133, 1);
  color: rgb(248, 133, 1);
}

.c-orange-border:hover {
  border: 1px solid rgb(248, 133, 1);
  background-color: rgb(248, 133, 1);
  color: white;
}

.dangerMessageForm {
  font-weight: bold;
  font-size: x-small;
  color: white;
  background-color: rgba(255, 0, 0, 0.89);
  height: 5px;
  padding: 3px 5px;
  border-radius: 2px;
  margin-right: 5px;
}

.secondaryMessageForm {
  font-weight: bold;
  font-size: x-small;
  color: white;
  background-color: rgba(216, 219, 40, 0.979);
  height: 5px;
  padding: 3px;
  border-radius: 4px;
  margin-right: 5px;
}

.okMessageForm {
  font-size: x-small;
  font-weight: bold;
  color: white;
  background-color: rgb(57, 179, 0);
  height: 5px;
  padding: 3px;
  border-radius: 4px;
  margin-right: 5px;
}

.warningMessageFrom {
  font-size: x-small;
  font-weight: bold;
  color: rgb(252, 167, 10);
}

.msgForm {
  text-align: justify;
  margin-top: -18px;
  margin-bottom: 18px;
}

.erreurContent {
  text-align: center;
}

/*.............Dropfilter...............*/

.chevronDown {
  float: right;
  margin-right: 2%;
}

.dropdown {
  width: 100%;
}

.list-content {
  width: 100%;
  overflow: auto;
  max-height: 265px;
  font-size: small;
}

.placeholder {
  float: left;
  margin-left: 5px;
  white-space: nowrap;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.errorContent {
  width: 100%;
  background-color: rgba(255, 0, 0, 0.822);
  height: 35px;
  text-align: center;
  margin-bottom: 20px;
  font-size: small;
  padding: 8px;
  border-radius: 4px;
  color: white;
}

.myInput {
  font-size: small;
  height: 35px;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding-left: 5%;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  width: 100%;
  border: 1px solid #ddd;
  z-index: 1;
  max-height: 300px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

/*switch*/
.switch {
  margin-top: 5px;
  position: relative;
  display: inline-block;
  width: 40px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 7px;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid rgba(0, 0, 0, 0.178);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  bottom: -6px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid rgba(0, 0, 0, 0.247);
}

input:checked + .slider {
  background-color: rgba(0, 187, 0, 0.87);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 45px;
  height: 12px;
}

.slider.round:before {
  border-radius: 100%;
  height: 21px;
  width: 21px;
}
/*................................................*/

@media screen and (max-width: 992px) {
  .responsive {
    min-width: 100%;
  }
}

.dateDispo {
  background-color: rgb(248, 133, 1);
  padding: 0.7%;
  padding-left: 2%;
  padding-right: 2%;
  color: white;
  border-radius: 100px;
}

.status {
  height: 35px;
  width: 100%;
  border-radius: 4px;
  color: white;
  font-size: small;
  padding-top: 5%;
  text-align: center;
}

.success {
  background-color: rgb(0, 175, 0);
}

.danger {
  background-color: rgba(255, 39, 39, 0.87);
}

.warning {
  background-color: rgb(255, 208, 0);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
