@media only screen and (max-width: 1199px) {

  .nav-item{
    font-size: small !important;
  }
  .btn-toogle{
    font-size: small !important;
  }
  .catForm{
    min-width: 8em !important;
    min-height: 8em !important;
  }
  .div-card-categorie-edit{
    min-height: 6em !important;
  }
  .imageContent-categorie{
    width: 5em !important;
    height: 5em !important;
    max-width: 5em !important;
    max-height: 5em !important;
  }

  .container-input-form input{
    font-size: small !important;
  }
  .div-input-categorie-edit{
    font-size: small;
    width: 48% !important;
  }
  .div-btn-categorie-edit{
    padding: 0 !important;
    /* background-color: teal; */
    padding-left: 0.5em !important;
    font-size: small;
    justify-content: space-around !important;
    /* padding: 0.5em !important; */
  }
  .div-btn-categorie-edit input{
    font-size: smaller !important;
    /* width: 100% !important; */
  }
  .accordion{
    flex-direction: column !important;
  }
  .designation{
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 991.5px){
 .dropCategorieSCEdit {
   width: 80% !important;
 }
 .div-btn-sousCategorie-edit{
  margin-left: 0.5%;
}
}

@media only screen and (max-width: 768px){
  .container-admin-produit{
    padding: 0 !important;
  }
  .container-edit-categorie{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .form-sousCategorie{
    flex-direction: column !important;
  }
  .dropdownCategorie{
    margin: 0 0 1em 0 !important
  }
  .div-btn-sousCategorie-edit{
    margin-left: 0.5%;
  }
  .btnSC {
    margin-left: 1% !important;
  }
  .txtSousCate {
    margin-right: 1% !important;
  }
  .container-input-form-SC{
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 540.5px) {
  .div-card-sousCategorie-edit{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 3%;
    padding: 2%;
    border-bottom: 2px solid rgb(209, 209, 209);
  }
  .catInfoContainer{
    width: 100% ;
  }
  .formEditSC{
    width: 100%;
  }
}

@media only screen and (max-width: 450px){
  .formEditSC {
    display: flex;
    flex-direction: column !important;
    justify-content: space-around;
    align-items: center;
  }
  .formEditSC input[type="text"] {
    margin-top: 3%;
  }
  .btnSC {
    margin-top: 3%;
  }
  .div-btn-sousCategorie-edit {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .div-card-categorie-edit{
    min-height: 10em !important;
  }
  .div-image-categorie-edit{
    width: 9em !important;
  }
  .imageContent-categorie{
    height: 8em !important;
    width: 8em !important;
  }
  .div-form-categorie-edit{
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
    
  }
  .div-input-categorie-edit{
    /* background-color: teal; */
    padding: 0.5em;
    width: 100% !important;
    height: 50% !important;
  }
  .div-btn-categorie-edit{
    /* background-color: tomato; */
    padding: 0 !important;
    height: 50% !important;
    width: 100% !important;
    /* font-size: smaller; */
    justify-content: space-around !important;
    /* background-color: teal; */
    width: 100% !important;
    /* padding: 0.5em !important; */
  }
  .div-btn-categorie-edit input{
    width: 100% !important;
    margin: 0.2em;
  }
}

@media only screen and (max-width: 375px){
  .catForm{
    max-width: 8em !important;
    max-height: 8em !important;
  }
  .addForm{
    width: auto;
    height: auto !important;
  }
  .div-card-categorie-edit{
    flex-direction: column !important;
    min-height: 25em !important;
    width: 100% !important;
    padding: 1em;
  }
  .div-image-categorie-edit{
    /* background-color: teal; */
    height: 65% !important;
    width: 100% !important;
    align-items: center !important;
  }
  .imageContent-categorie{
    height: 215px !important;
    width:  215px !important;
  }
  .div-form-categorie-edit{
    height: 35% !important;
  }
  .div-btn-categorie-edit{
    justify-content: space-between !important;
    /* background-color: teal; */
    width: 100% !important;
    /* padding: 0.5em !important; */
  }
  .div-btn-categorie-edit input{
    margin: 0.25em;
    width: 100% !important;
    font-size: smaller !important;
  }
}
@media only screen and (max-width: 320px){
  .catForm{
    max-height: 8em !important;
    max-width: 8em !important;
  }
  .imageContent-categorie{
    height: 15em !important;
    width: 12em !important;
  }
  .container-input-form{
    flex-direction: column !important;
  }
}

.container-admin-produit{
    padding-left: 1em;
    height: 100%;
    width: 100%;
}
.nav-tabs {
    display: flex;
    justify-content: flex-start;
    align-self: center;
}
.nav-item .active{
  background-color: #D7E7EC !important;
}
.div-admin-produit-link{
    font-family: "Fredoka One";
    color: black;
}
.div-admin-produit-link.active{
    color: black;
}
.div-admin-produit-link:hover{
  color: black;
}
.container-tabs-produit{
    /* background-color: teal; */
    border: 1px solid #dee2e6;
    border-top: none;
    padding: 1em;
    height: auto;
}
.container-admin-categorie{
    /* background-color: snow ; */
    display: flex;
    align-self: center;
    width: 100%;
    height: 100%;
}

.accordion{
  display: flex;
  flex-direction: column;
  /* background-color: chocolate; */
  width: 100%;
  height: auto; 
}
.div-categorie-accordion-toogle{
  /* background-color: brown; */
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.btn-toogle{
  background-color: white;
  min-height: 2.5em;
  min-width: 8em;
  transition: 250ms;
  font-size: medium;
  font-family: 'Fredoka One';
  color: grey;
  /* text-shadow: 0.5px 0.5px 2px rgb(59, 59, 59); */
  box-shadow: 0 0 3px 0 grey inset;

}
.btn-toogle:hover{
  color: white;
  background-color: rgba(248, 128, 32, 0.8);
  box-shadow: 0 0 5px grey ;
  transition: 500ms;
}
.btn-toogle.active{
  color: white;
  transition: 250ms;
  transform: scale(1.05);
  box-shadow: 0 0 5px 0 #F88020 ;
  background-color: #F88020;

}
.div-categorie-accordion-collapse{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  width: 100%;
  /* background-color: black; */
}
.collapse.show{
  height: auto;
  /* background-color: burlywood; */
}

/* CATEGORIE */

.container-form-categorie{
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  /* background-color: aqua; */
}

.container-image-upload{
  margin-top: 2em;
  display: flex;
  justify-content: center;
  height: auto;
  width: 100%;
  /* background-color: black; */
}
.container-input-form{
  /* background-color: aqua; */
  /* padding: 1em; */
  margin-top: 1.5em !important;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#input-valid{
  margin: 0.5em;
  text-align: center;
  height: 2.5em;
  border-radius: 5px;
  border: none;
  color: rgb(99, 99, 99);
  box-shadow: 0 0 3px 0 grey;
  transition: 250ms;
}
#input-valid:focus{
  box-shadow: 0 0 5px #34C924;
}
#btn-valid{
  transition: 250ms;
  border: none;
  min-height: 2.5em;
  width: 8em;
  font-size: medium;
  font-weight: bold;
  color: #ffffff;
  border-radius: 0.5em;
  text-shadow: 1px 1px 2px grey;
  background-color: #D7E7EC;
}
#btn-valid:hover{
  transition: 150ms;
  box-shadow: 0 0 3px grey;
  transition: 250ms;
  background-color: #34C924;
}
.container-edit-categorie{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background-color: aquamarine; */
  padding: 1em;
  width: 100%;
  height: 100%;
}
.div-card-categorie-edit{
  max-height: 5em !important;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.div-card-categorie-edit:hover{
  box-shadow: 0 0 3px grey !important;
}
.div-image-categorie-edit{
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  /* padding: 0.5em; */
  width: 25%;
  height: 100%;
}

.div-form-categorie-edit{
  display: flex;
  flex-direction: row;
  /* background-color: coral; */
  width: 100%;
}
.div-input-categorie-edit{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}
.div-input-categorie-edit input{
  text-align: center;
  width: 100%;
  height: 2.5em;
  border-radius: 5px;
  border: none;
  color: rgb(99, 99, 99);
  box-shadow: 0 0 3px 0 grey;
  transition: 250ms;

}
.div-input-categorie-edit input:focus{
  transition: 250ms;
  box-shadow: 0 0 3px 1px #34C924;
}
.div-btn-categorie-edit{
  /* background-color: blueviolet; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  padding: 1em;
}
.div-btn-categorie-edit input{
  position: relative;
  outline: none;
  border: 1px solid transparent;
  transition: 250ms;
  border-radius: 0.5em;
  background-color: #D7E7EC;
  min-height: 3em;
  width: 8em;
  font-size: medium;
  font-family: "Open Sans";
  font-weight: bolder;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgb(59, 59, 59);
}
#btn-update:hover{
  box-shadow: 0 0 5px grey;
  background-color: #34C924;
  transition: 200ms;
}
#btn-delete:hover{
  box-shadow: 0 0 5px grey;
  background-color: #ff4d4d;
  transition: 200ms;
}
#btn-update:active{
  transition: 50ms;
  box-shadow: 0 0 3px #34C924 inset;
}
#btn-delete:active{
  transition: 100ms;
  box-shadow: 0 0 3px #ff4d4d inset;
}

/* //////////////////// */

.bord-dash {
  border-style: dashed;
  border-color: rgb(148, 148, 148);
}

.bord-dash-gray {
  border-color: rgb(148, 148, 148);
}

.imgContainer {
    height: auto;
    width: auto;
    position: absolute;
}

.imgDrop {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  /* background-color: #34C924; */
}

.imageContent-categorie {
  position: relative !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: rgb(231, 231, 231);
  width: 8.8em;
  height: 8.8em;
  transition: 150ms;
}
.imageContent-categorie:hover {
  transition: 250ms;
  opacity: 0.8;
}
.deletePhoto-categorie{
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: large;
  background-color: rgba(192, 192, 192, 0.678);
  margin-right: -1em;
  margin-bottom: 6.5em;
  border-radius: 4px;
  color: rgb(59, 59, 59);
  border-radius: 100%;
  padding-top: 2px;
}
.imageContent:hover {
  background-color: rgb(200, 200, 200);
}



/* SOUS CATEGORIE  */

/* Ajout */
.form-sousCategorie{
  margin-top: 1em;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.categorieSelect{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}  
.dropdownCategorie{
  background-color: transparent;
  flex: 1 ;
  width: 100%;
  padding: 0 1em 0 0;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 2%; */
}
.dropbtnCategorie{
  min-height: 2em;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  padding-left: 1%;
  padding-right: 1%;
  border-bottom: 2px solid darkgrey;
  transition: all 0.2s ease-in;
}
.dropbtnCategorie:hover{
  cursor: pointer;

}
.dropdownCategorie-content{
  flex: 1;
  max-height: 316px;
  height: auto;
  overflow-y: scroll;
  border: 2px solid darkgray;
  border-top: none;
  border-radius: 0 0 2% 2%;
}
.listCategorieContent{
  padding: 0;
  transition: all 0.5s ease-in-out;
}
.oneCategorie{
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 0.5em 1em 0.5em 1em; 
  border-bottom: 1px solid rgb(209, 209, 209) ;
  transition: all 0.2s ease-in;
}
.oneCategorie:hover{
  background-color:rgb(209, 209, 209);
  cursor: pointer;
}
.designation{
  margin-right: 3rem;
}
.categorieFound{
  /* background-color: pink; */
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.aucuneCategorieSelect{
  min-height: 88px;
  padding: 2%;
  margin-top: 1%;
  border: 1px solid rgb(209, 209, 209);
  text-align: center;
  height: auto;
}
.theCategorie{
  padding: 2%;
  border: 1px solid rgb(209, 209, 209);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-input-form-SC{
  background-color: transparent;
  margin-top: 1em;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

/* Modification*/
.form-sousCategorieEdit{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropCategorieSCEdit {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  margin: 2%;
}
.dropdownCategorieSCEdit-content{
  max-height: 200px;
  overflow-y: scroll;
}
.dropBtnCategorieSCEdit{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  border: 1.5px solid rgba(148, 148, 148, 0.548);
  background-color: rgb(241, 241, 241);
}
.dropBtnCategorieSCEdit:hover{
  cursor: pointer;
}

.txtCateInfo{
  text-align: center;
  font-size: large;
  font-weight: bold;
}
.catInfo{
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  /* background-color: rgba(209, 209, 209, 0.719); */
  border-radius: 3%;
}

.oneCategorieSCEdit{  
  border: 1px solid rgb(200, 200, 200);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3%;
  padding-top: 3%;
  padding-left: 4%;
  padding-right: 4%;
  /* background-color: rgba(209, 209, 209, 0.719); */
}
.oneCategorieSCEdit:hover{
  cursor: pointer;
  background-color: rgb(200, 200, 200);
}
.listSousCategorie{
  width: 100%;
}

.catInfoContainer{
  display: flex;
  flex-direction: column;
}

.formEditSC{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2%;
  margin-top: 1%;
  width: 100%;
  border: 2px solid rgb(209, 209, 209);
}

.txtSousCate{
  width: 50px;
  height: 35px;
  text-align: center;
  font-size: large;
  font-weight: bold;
  color: #d88020;
  margin-right: 3%;
  border: 1px solid;
  border-radius: 100%;
}

.formEditSC input[type="text"] {
  flex: 1;
}
.div-btn-sousCategorie-edit{
  flex: 1;
  display: flex;
  justify-content: space-around;
}
/* .btnSC{
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  min-width: 94px !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
} */
.btnSC{
  transition: 250ms;
  border: none;
  min-height: 2.5em;
  width: 8em;
  font-size: medium;
  font-weight: bold;
  color: #ffffff;
  border-radius: 0.5em;
  text-shadow: 1px 1px 2px grey;
  background-color: #D7E7EC;
}
.btnSC:hover{
  transition: 150ms;
  box-shadow: 0 0 3px grey;
  transition: 250ms;
  background-color: #34C924;
}
