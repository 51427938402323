@media only screen and (max-width : 768px)  {
    .div-menu{
        margin-top: 1em;
        margin-bottom: 1em;
        flex-direction: row !important;
        border-bottom: 3px solid #DCDCDC;
        padding-bottom: 2.5em;
    }
    .menu-items{
        box-shadow: 0 0 2px grey;
    }
    .menu-link{
        transition: 150ms;
        box-shadow: 0 0 5px 0 #DCDCDC inset;
    }
    .menu-link.active{
        transition: 150ms;
        color: white !important;
        box-shadow: 0 2px 3px #f88020;
        opacity: 1 !important;
        background-color: #F88020;
        transform: scale(1.1)
    }
    .menu-link, .menu-link.active{
        transition: 150ms;
        justify-content: center !important;
    }
}
@media only screen and (max-width : 425px){
    .menu-link{
        height: auto !important;
    }
    .icon-sidebar{
        width: auto !important; 
        height: auto !important;
        margin: 0.1em !important;
    }
    .icon-sidebar svg{
        height: 2em !important;
        width: 2em !important;
    }
    .title-sidebar{
        display: none;
    }
}

/* .btn{
    display: none;
} */
.nav-menu{
    /* background-color: aqua; */
    margin: 0;
    width: 100%;
    height: 100%;
    /* display: none; */
    
}
.div-menu{
    width: 100%;
    height: 13%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.menu-items{
    width: 100%;
}
.menu-link{
    /* background-color: chartreuse; */
    text-decoration: none !important;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    width: 100%;
    height: 2.5em;
    transition: 100ms;
}

.menu-link.active{
    color:#F88020; 
}
.menu-link:hover{
    transition: 100ms;
    color: #F88020;
    opacity: 0.75;
}
.icon-sidebar{
    margin-right: 0.5em;
}
.title-sidebar{
    font-weight: bolder;
    font-size: medium;
}
.menu-link-user:hover{
    transition: 100ms;
    color: #F88020;
    opacity: 0.75;
}