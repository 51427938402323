@media screen and (max-width: 767px) {
  .div-apropos{
    width: 100% !important;
    height: 100% !important;
  }
  .col-md-3{
    text-align: start !important;
    max-width: 100% !important;
  }
  
}

@media screen and (max-width: 575px) {
  
  #footerContainer h3{
      font-size: 30px;
      margin-bottom: 0;
  }
  
  #footerContainer div:not(.social-media-link) {
    display: flex ;
    flex-direction: column ;
    align-items: center ;
  }
  .col-md-3{
    text-align: start !important;
    max-width: 100% !important;
  }
  .underline{
    margin-bottom: 0.5em ;
    width: auto !important;
  }
  .div-apropos{
    margin-bottom: 1em !important;
  }
  .div-apropos-link{
    margin-top: 1em;
    height: 70px !important;
    align-items: flex-start !important;
  }
  .linkp{
    flex-direction: row !important;
    justify-content: flex-start !important;
  }
  #txt-login{
    margin: 0 !important;
  }
  .txt-login{
    margin: 0 !important;
  }
  #txt-marche{
    margin: 0 !important;
  }

  .txt-marche {
    margin: 0 !important;
  }
}
#txt-login{
  margin-top: 0.5em ;
  margin-bottom: 0.5em ;
  width: 6.5em ;
  transition: 150ms;
}
#txt-login:hover{
  transition: 200ms;
  transform: scale(1.05);
  cursor: pointer;
}
.txt-login{
  margin-top: 0.5em ;
  margin-bottom: 0.5em ;
  width: 6.5em ;
  transition: 150ms;
}
.txt-login:hover{
  transition: 200ms;
  transform: scale(1.05);
  cursor: pointer;
}
#txt-marche{
  margin-top: 0.5em ;
  margin-bottom: 0.5em ;
  width: 10em;
  transition: 150ms;
}
#txt-marche:hover{
  transition: 200ms;
  transform: scale(1.05);
  cursor: pointer;
}
.txt-marche{
  margin-top: 0.5em ;
  margin-bottom: 0.5em ;
  width: 10em;
  transition: 150ms;
}
.txt-marche:hover{
  transition: 200ms;
  transform: scale(1.05);
  cursor: pointer;
}

#footerContainer {
  box-shadow: 0 0 5px grey inset;
  background-color: #d7e7ec;
  color: rgb(73, 73, 73);
  padding-top: 30px;  
  font-size: small;
  font-family: 'Open Sans';
}

.link, .link:hover, .link:focus, .link:active{
  text-decoration: none;
  color: unset;
  font-size: smaller;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.logo-footer{
  cursor: pointer;
  width: 8em;
  height: 7em;
  margin-top: 20px;
}
#emailTextField {
  border: none;
  border-radius: 100px;
  height: 35px;
  min-width: 200px;
  padding:10px 20px;
  font-size: small;
  color: black;
  margin-bottom: 10px;
  box-shadow: 0 0 3px #34C924;
  display: block;
}
#footerContainer h3{
  font-size: larger;
}

.underline {
  width: 45px;
  margin-top: 0.5em;
  height: 3px;
  margin-bottom: 1em;
  background-color: rgb(73, 73, 73);
}
.div-apropos-link{
  /* background-color: yellow; */
  height: 6em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

}
.linkp{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  font-size: smaller;
  align-items: center;
  gap: 10px;
  margin: 4px 0px;
}
.linkp img{
  margin-right: 1em;
  height: 1.5em;
  width: 1.5em;
}
#end {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #34C924;
  width: 100%;
  height: 50px;
  font-size: x-small;
  color: #d7e7ec;
}
.text-end{
  color: #ffffff;
  font-size: small;
}

.footer-link{
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0px;
  cursor: pointer;
}

.footer-link a{
  text-decoration: none;
  color: black;
}

.footer-link a:hover{
  text-decoration: underline;
}

.linkp svg{
  color: #f57400;
}

@media screen and (max-width: 480px) {
  #emailTextField {
    margin-bottom: 0;
  }
  .btn-newsletter {
    width: 100%;
    margin: 10px 0;
  }
  .div-apropos-link {
    margin-bottom: 50px;
    gap: 10px;
  }
  .social-media-link{
    display: flex;
    flex-direction: row;
    gap: 50px;
  }
}
@media screen and (min-width: 480px) {
  .social-media-link{
    display: flex;
    flex-direction: column;
  }
}