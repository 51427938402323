@media screen {
}

.container-admin-annonce {
  background-color: transparent;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  margin-bottom: 3em;
}
.div-admin-search {
  align-self: flex-end;
}
.admin-search {
  padding-left: 1em;
  border: 1px solid #a8a8a8;
  box-shadow: 0 0 1px grey;
  border-radius: 2px;
}
.div-admin-annonce {
  padding-left: 1em;
}

/* annonce data */

.card {
  min-height: 8em;
  padding: 0;
  transition: 150ms;
  border-radius: 2px;
  margin-top: 1em;
  width: 100%;
  height: auto;
  box-shadow: 0 0 1px rgb(56, 55, 55);
}
.card-admin-annonce {
  min-height: 0 !important;
  height: min-content;
}

.card-container:hover {
  transition: 150ms;
  box-shadow: 0 0 5px grey;
}

.card:hover {
  transition: 150ms;
  box-shadow: 0 0 5px grey;
}
.card-body {
  min-height: 0px !important;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  flex: 1;
}
.hover {
  max-height: 6em;
}
.container-admin-annonce-image {
  flex: 1;
}
.div-admin-annonce-image {
  width: 100%;
  height: 100%;
}
.image-annonce {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}
.container-admin-annonce-info {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 7;
}
.container-admin-annonce-info1 {
  /* background-color: darkolivegreen; */
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.5em;
}
.div-admin-annonce-title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.div-admin-annonce-price {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.div-admin-annonce-date-create {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.container-admin-annonce-info2 {
  /* background-color: darkcyan; */
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
}

.div-admin-annonce-periode {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.container-admin-annonce-info3 {
  /* background-color: darkgoldenrod; */
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 33%;
}
.div-admin-annonce-home {
  /* background-color: cornsilk; */
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.div-admin-annonce-phone {
  /* background-color: cornflowerblue; */
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.div-admin-annonce-email {
  /* background-color: blueviolet; */
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.container-admin-annonce-status {
  /* background-color: black; */
  border-left: 2px rgb(226, 226, 226) solid;
  padding: 0.5em;
  flex: 1;
}
.div-admin-annonce-status {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

#stockA {
  background-color: rgb(235, 166, 40);
}
#stockV {
  background-color: rgb(52, 201, 36);
}
#stockF {
  background-color: rgb(232, 81, 81);
}
.titleAnnonce {
  color: #000000;
  font-size: medium;
  font-weight: bold;
}
.icons {
  width: 1em;
  height: 1em;
}

.price {
  font-size: larger;
  font-weight: bold;
  color: rgb(248, 128, 32);
}

.stock {
  font-size: small;
  font-family: "Fredoka One", sans-serif;
  color: white;
  text-align: center;
  padding: 5px;
  width: 100%;
  height: auto;
  border-radius: 2px;
}

.card-subtitle {
  color: #b6bfc9;
  margin: 0;
}
/* Modal */
.rs-modal-backdrop.fade.in {
  opacity: 0.1;
}
.rs-modal-body {
  align-items: center;
  justify-content: center;
  padding: 1.5em !important;
}
.rs-modal-content {
  padding: 0;
}

.Spin {
  width: 100%;
  height: auto;
  margin-top: 5em;
  margin-bottom: 20em;
}

.card-actions {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.delete-button {
  align-self: center;
  font-size: small;
  width: 85px;
  height: 2.5em;
  border-radius: 2px;
  background-color: #fe5f5f;
  color: white;
  transition: 75ms;
}

.delete-button:hover {
  background-color: #e04c4c;
}
