
.header-admin{
    background-color: #d7e7ec;
    position: relative;
    height: auto;
    box-shadow: 0 0 5px grey;
}

.contentHeaderAdmin{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding-top: 2%;
    padding-bottom: 2%;
}
.logoHeaderAdmin{
    justify-self: flex-start;
}
.adminAvatar{
    justify-self: flex-end;
}
.itemProfilAdmin{
    font-size: medium;
    padding: 5%;
    padding-left: 15px;
    cursor: pointer;
}
.itemProfilAdmin:hover {
    background-color: rgb(255, 145, 0);
    color: white;
    border-radius: 4px;
}
.adminAvatar .img-circle:hover {
cursor: pointer;
}
.adminName {
    font-size: large;
    min-width: 200px;
    text-align: left;
  }
  
.adminUsername {
font-size: medium;
color: gray;
}

#loginBtnAdmin{
    width: 125px;
}