.container-admin-user{
    /* background-color: burlywood; */
    padding-left: 1em;
    width: 100%;
    height: 100%;
}
.container-admin-user-accordion{
    /* background-color: aqua; */
    width: 100%;
    height: 100%;
}

.accordion-toogle-admin-users{
    /* background-color: burlywood; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}
.toogle-admin-user{
    padding: 0;
    text-align: center;
    height: 3em;
    width: 100%;
    font-size: medium;
    font-weight: unset;
    font-family: "Fredoka One";
    border: none;
    box-shadow: 0 0 3px grey inset;
    background-color: white
}
.toogle-admin-user:hover{
    transform: scale(1.025);
    transition: 100ms;
}
.toogle-admin-user.active{
    box-shadow: unset !important;
    transition: 150ms;
    transform: scale(1.025);
    background-color: #f88020;
    color: white;
}
.accordion-admin-collapse-users div.collapse.show{
    border-top: none;
}

/* utilisateur */
.container-utilisateur{
    padding-top: 1em;
}

/* Administrateur */
.container-administrateur{
    padding-top: 1em;
}
.div-add-admin-btn{
    margin-bottom: 1em;
}

#btn-addAdmin{
    display: flex;
    flex-direction: row;
    font-size: small;
    align-self: center;
    justify-content: center;
    min-width: 220px;
    height: 3em;
    border-radius: 2px;
    background-color: #34c924;
    color: white;
    padding: 0 10px;
    transition: 75ms;
}
#btn-addAdmin:hover:focus{
    box-shadow: 0 0 5px grey;
    transition: 75ms;
}
#btn-addAdmin span{
    height: 100%;
    width: 75%;
}
#icon-add{
    height: 100%;
    width: 25%;
}
#input-admin-email{
    border: none;
    box-shadow: 0 0 3px grey inset;
    width: 15em;
    height: 2em;
    transition: 75ms;
}
#input-admin-email:focus{
    box-shadow: 0 0 3px #34c924 inset;
    transition: 75ms;
}
#btn-admin-envoie{
    margin-left: 1em;
    font-size: small;
    width: 85px;
    height: 2.5em;
    border-radius: 5px;
    background-color: #34c924;
    color: white;
    transition: 75ms;
}
#btn-admin-envoie:hover{
    border: none;
    box-shadow: 0 0 3px grey;
}
#btn-admin-envoie:active{
    border: none;
    box-shadow: 0 0 5px grey inset;
}
#btn-delete-admin{
    align-self: center;
    font-size: small;
    width: 85px;
    height: 2.5em;
    border-radius: 2px;
    background-color: #fe5f5f;
    color: white;
    transition: 75ms;
}