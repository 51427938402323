.content-reset{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    /* margin-bottom: 8em */
}
.login-content{
    margin-left: 1em !important;
    margin-right: 1em !important;
}
.container-login{
    width: auto;
    align-self: center;
}
.title{
    margin-top: 1em;
    font-size: 20px !important;
    color: coral;
}
.body-content{
    transition: 500ms;
    border: solid 1px gainsboro;
    border-radius: 10px;
    width: auto;
    height: 50%;
    margin: 1em;
    padding: 1.5em;
}
.body-contentP{
    transition: 500ms;
    border: solid 1px gainsboro;
    border-radius: 10px;
    min-width: 360px;
    height: 100%;
    margin: 1em;
    padding: 1em;
}
.body-content:hover{
    transition: 250ms;
    box-shadow: 0 0 10px grey;
}
.body-contentP:hover{
    transition: 250ms;
    box-shadow: 0 0 10px grey;
}
.password-container{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: auto;
    height: auto;
}